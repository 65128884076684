<template>
    <div>
        <div class="head">
            <div class="nav">
                <div @click="goBack">
                    <i style="font-size: 20px; padding-top: 2px" class="el-icon-arrow-left"></i>
                    返回首页
                </div>
            </div>
        </div>
        <div class="main-box">
            <div class="main">
                <div class="step">
                    <div class="step-item" v-for="(item, index) in stepList" :key="item.id">
                        <div>
                            <img v-show="index <= active" src="../../assets/images/home/qr.png" alt="" />
                        </div>
                        {{ item.name }}
                    </div>
                </div>
                <div style="padding: 0 30px">
                    <div class="state">
                        <img src="../../assets/images/serve/gth.png" alt="" />
                        正在 <span>入驻开店</span>
                    </div>
                    <!-- 店铺信息 -->
                    <div v-show="active == 0">
                        <div class="avatar">
                            *头像(尺寸：198x198;支持jpg、png格式图片):
                            <div class="avatar-item">
                                <el-upload :limit="1" :action="action" :file-list="headPic" :on-success="beforeLoad" list-type="picture-card" :on-remove="removeImg">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                            </div>
                        </div>
                        <el-form style="margin-top: 40px" label-position="left" label-width="100px" :model="form" @submit.native.prevent>
                            <el-form-item label="*店铺名称">
                                <el-input placeholder="请输入你的店铺名称（不超过10个字符）" v-model="form1.name"></el-input>
                            </el-form-item>
                            <el-form-item label="*经营范围/业务介绍" label-width="180px">
                                <el-input v-model="form1.msg" :autosize="{ minRows: 4, maxRows: 10 }" type="textarea" placeholder="请输入你的店铺介绍（不超过50个字符）"></el-input>
                            </el-form-item>
                            <el-form-item label="*地区：" label-width="80px">
                                <div class="code">
                                    <el-select v-model="form1.province" placeholder="地区">
                                        <el-option v-for="item in proList" :key="item.id" :label="item.province" :value="item.provinceid"></el-option>
                                    </el-select>
                                    <div style="margin: 0 20px">省</div>
                                    <el-select v-if="form1.province != ''" v-model="form1.city" placeholder="地区">
                                        <el-option v-for="item in cityList" :key="item.id" :label="item.city" :value="item.cityid"></el-option>
                                    </el-select>
                                    <div v-if="form1.province != ''" style="margin: 0 20px">市</div>
                                </div>
                            </el-form-item>
                            <div class="title-t">*经营类别（可多选）</div>
                            <div class="check" v-for="item in allClass" :key="item.id">
                                <div class="check-head">{{ item.name }}</div>
                                <div class="check-box">
                                    <el-checkbox class="check-item" :label="val.name" v-model="val.status" v-for="val in item.littleList" :key="val.id" name="type"></el-checkbox>
                                </div>
                            </div>
                            <div class="upload">
                                <div class="lable">上传店铺咨询二维码照片(支持jpg、png格式图片)：</div>
                                <el-upload :limit="1" :action="action" :file-list="weCode" :on-success="beforeLoadW" list-type="picture-card" :on-remove="removeImgW">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                            </div>
                            <!-- 底部按钮 -->
                            <div class="foot-btn">
                                <div @click="goSettled">返回上一步</div>
                                <el-button class="f-btn" @click="next(0)">下一步</el-button>
                            </div>
                        </el-form>
                    </div>
                    <!-- 宣传图 -->
                    <div v-show="active == 1">
                        <div class="publicity">
                            <el-upload :limit="5" :action="action" :file-list="fileList" :on-success="beforeLoadL" list-type="picture-card" :on-remove="removeImgL">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </div>
                        <!-- 底部按钮 -->
                        <div class="foot-btn">
                            <div @click="pre">返回上一步</div>
                            <el-button class="f-btn" @click="next(1)">下一步</el-button>
                        </div>
                    </div>
                    <!-- 详情信息 -->
                    <div v-show="active == 2">
                        <el-form style="margin-top: 40px" label-position="left" label-width="80px" :model="form" @submit.native.prevent>
                            <el-form-item label="标题1：">
                                <el-input placeholder="不超过10个字符" v-model="contentList[0].title"></el-input>
                            </el-form-item>
                            <vue-editor v-model="contentList[0].content" id="editor" useCustomImageHandler @image-added="handleImageAdded" />
                            <el-form-item style="margin-top: 40px" label="标题2">
                                <el-input placeholder="不超过10个字符" v-model="contentList[1].title"></el-input>
                            </el-form-item>
                            <vue-editor v-model="contentList[1].content" id="editor2" useCustomImageHandler @image-added="handleImageAdded2" />
                            <!-- 底部按钮 -->
                            <div class="foot-btn">
                                <div @click="pre">返回上一步</div>
                                <el-button class="f-btn" @click="next(2)">下一步</el-button>
                            </div>
                        </el-form>
                    </div>
                    <!-- 预览 -->
                    <div v-if="active == 3">
                        <div class="chat-img">
                            店铺预览
                            <img src="../../assets/images/serve/wechat.png" alt="" />
                        </div>

                        <div class="shop-box">
                            <div class="shop-info">
                                <div class="shop-img">
                                    <img :src="$util.host + shopInfo.headPic" alt="" />
                                </div>
                                <div style="flex: 1">
                                    <div class="shop-title">
                                        <div class="shop-name">{{ shopInfo.name }}</div>
                                        <!-- <img
                      style="width: 33px; height: 22px"
                      src="../../assets/images/serve/qilogo.png"
                      alt=""
                    /> -->
                                        <div class="shop-tag">
                                            <div class="bor">近一年成交率: <span>0%</span></div>
                                            <div class="bor">好评率: <span>0%</span></div>
                                            <div>近一年发布量: <span>0单</span></div>
                                        </div>
                                        <div>开店时间 {{ shopInfo.createTime }}</div>
                                        <!-- <img class="dw" src="../../assets/images/serve/dw.png" alt="">
                                        <div>广东深圳</div> -->
                                    </div>
                                    <div class="shop-business">
                                        <div>{{ shopInfo.msg }}</div>
                                    </div>
                                    <div class="shop-business" v-if="shopInfo.shopClassify">
                                        <div>
                                            经营类别：<span v-for="item in shopInfo.shopClassify" :key="item.baseId">{{ item.littleItems[0].name }} |
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 轮播图 -->
                        <el-carousel height="600px" arrow="never" trigger="click" v-if="shopInfo.images">
                            <el-carousel-item v-for="item in shopInfo.images.split(',')" :key="item">
                                <div class="swiper">
                                    <img :src="$util.host + item" alt="" />
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                        <div class="mian">
                            <div class="main-title">商品服务</div>
                            <!-- 分类 -->
                            <div class="classification">
                                <div class="slece">
                                    <el-dropdown trigger="click" placement="bottom">
                                        <span class="el-dropdown-link">
                                            类别<i class="el-icon-arrow-down el-icon--right"></i>
                                        </span>
                                        <el-dropdown-menu></el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                                <div class="slece">
                                    <el-dropdown trigger="click" placement="bottom">
                                        <span class="el-dropdown-link">
                                            地区<i class="el-icon-arrow-down el-icon--right"></i>
                                        </span>
                                        <el-dropdown-menu></el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                                <div class="slece">
                                    <el-dropdown trigger="click" placement="bottom">
                                        <span class="el-dropdown-link">
                                            上架时间<i class="el-icon-arrow-down el-icon--right"></i>
                                        </span>
                                        <el-dropdown-menu></el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                            </div>
                            <!-- 分类内容 -->
                            <div class="classification-content">店铺还没设置商品</div>
                            <!-- 公司介绍 -->
                            <p class="main-title">公司介绍</p>
                            <div v-for="item in contentList" style="padding-bottom: 20px">
                                <p style="padding: 17px 20px" v-if="item.title != ''">
                                    标题：{{ item.title }}
                                </p>
                                <div v-html="item.content" class="shopDetail"></div>
                            </div>
                            <!-- <div class="pagination">
                                <el-pagination background layout="prev, pager, next" :page-size="24" :total="240">
                                </el-pagination>
                                <div class="total">共8000件/24页</div>
                                <div class="jump">
                                    跳转到
                                    <input type="number">
                                    页
                                </div>
                                <el-button style="background:#2EA7E0;color:#fff;width:108px;height:50px;font-size:20px">前往</el-button>
                            </div> -->
                        </div>
                        <!-- 底部按钮 -->
                        <div class="foot-btn">
                            <div @click="pre">返回上一步</div>
                            <el-button class="f-btn" @click="next">下一步</el-button>
                        </div>
                    </div>
                    <!-- 信息认证 -->
                    <div v-show="active == 4" style="margin-top: 30px">
                        <div v-if="obtion == 0">
                            <div class="hint">*预付金额</div>
                            <div class="backJd">
                                <div class="ysmoney">{{ needMoney }}</div>
                                <div style="margin-left: 20px">
                                    保证金是参与平台经营和资金提现的必要条件，在店铺注销成功后将全额原路退回。
                                </div>
                            </div>
                            <div class="hint">*支付方式</div>
                            <div class="payment" v-if="!qrCodeShow">
                                <el-radio v-model="payForm.payType" :label="0" class="raido">
                                    <div class="raido-info">
                                        <div>
                                            <img style="width: 20px; height: 19px" src="../../assets/images/serve/wdqb.png" alt="" />
                                            我的钱包
                                            <span>余额：{{ info.balance }}元</span>
                                        </div>
                                    </div>
                                </el-radio>
                                <div>平台</div>
                            </div>
                            <div class="payment" v-if="!qrCodeShow">
                                <el-radio v-model="payForm.payType" :label="1" class="raido">
                                    <div class="raido-info">
                                        <div>
                                            <img style="width: 22px; height: 22px" src="../../assets/images/serve/zfb.png" alt="" />
                                            支付宝
                                        </div>
                                    </div>
                                </el-radio>
                            </div>
                            <div class="payment" v-if="!qrCodeShow">
                                <el-radio v-model="payForm.payType" :label="2" class="raido">
                                    <div class="raido-info">
                                        <div>
                                            <img style="width: 21px; height: 18px" src="../../assets/images/serve/wx.png" alt="" />
                                            微信
                                        </div>
                                    </div>
                                </el-radio>
                            </div>

                            <div class="payment" v-if="qrCodeShow && payForm.payType == 0">
                                <el-radio v-model="payForm.payType" :label="0" class="raido">
                                    <div class="raido-info">
                                        <div>
                                            <img style="width: 20px; height: 19px" src="../../assets/images/serve/wdqb.png" alt="" />
                                            我的钱包
                                            <span>余额：{{ info.balance }}元</span>
                                        </div>
                                    </div>
                                </el-radio>
                                <div>平台</div>
                            </div>
                            <div class="payment" v-if="qrCodeShow && payForm.payType == 1">
                                <el-radio v-model="payForm.payType" :label="1" class="raido">
                                    <div class="raido-info">
                                        <div>
                                            <img style="width: 22px; height: 22px" src="../../assets/images/serve/zfb.png" alt="" />
                                            支付宝
                                        </div>
                                    </div>
                                </el-radio>
                            </div>
                            <div class="payment" v-if="qrCodeShow && payForm.payType == 2">
                                <el-radio v-model="payForm.payType" :label="2" class="raido">
                                    <div class="raido-info">
                                        <div>
                                            <img style="width: 21px; height: 18px" src="../../assets/images/serve/wx.png" alt="" />
                                            微信
                                        </div>
                                    </div>
                                </el-radio>
                            </div>

                            <div v-show="payForm.payType == 0">
                                <div class="hint">*支付密码</div>
                                <div class="paypass">
                                    <input v-for="(item, index) in captchas" type="password" :key="index" v-model="item.num" :id="'captcha' + index" @input="inputFinash(index)" @focus="adjust(index)" @keydown="inputDirection(index)" class="captcha_input_box row-center" maxlength="1" />
                                </div>
                                <div class="privacy">你的隐私正在保护中...</div>
                                <!-- 底部按钮 -->
                                <div class="foot-btn">
                                    <div @click="pre">返回上一步</div>
                                    <el-button class="f-btn" @click="next(4)">下一步</el-button>
                                </div>
                            </div>
                            <!-- 底部按钮 -->
                            <div v-show="payForm.payType != 0 && !qrCodeShow">
                                <div class="foot-btn">
                                    <!-- <div @click="pre">返回上一步</div> -->
                                    <el-button class="f-btn" @click="next(4)">生成付款二维码</el-button>
                                </div>
                            </div>
                            <!-- 底部按钮 -->
                            <div v-show="payForm.payType != 0 && qrCodeShow">
                                <div class="foot-btn" style="align-items: flex-end">
                                    <!-- <div @click="pre">返回上一步</div> -->
                                    <div class="qr-code" ref="qrCodeUrl" v-show="qrCodeShow"></div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="no-win">
                                <img src="../../assets/images/serve/qr.png" alt="" />
                                支付成功！平台管理员审核中，请耐心等待
                            </div>
                        </div>
                    </div>
                    <!-- 入驻成功 -->
                    <div v-if="active == 5">
                        <div class="s-title">请认证你的店铺</div>
                        <el-form style="margin-top: 20px" label-position="right" label-width="250px" :model="form" @submit.native.prevent>
                            <el-form-item label="*用户姓名：">
                                <div class="code">
                                    <input style="width: 563px" v-model="form.userName" type="text" />
                                </div>
                            </el-form-item>
                            <el-form-item label="*用户身份证号：">
                                <div class="code">
                                    <input style="width: 563px" v-model="form.idNumber" type="text" />
                                </div>
                            </el-form-item>
                            <el-form-item label="*手上传姓名对应的身份证照片(支持jpg、png格式图片)：">
                                <div class="upload">
                                    <div>
                                        <el-upload :limit="1" :action="action" :on-success="beforeLoadZ" list-type="picture-card" :on-remove="removeImgZ">
                                            <i class="el-icon-plus"></i>
                                        </el-upload>
                                        <div class="uload-name">身份证国徽面</div>
                                    </div>
                                    <div>
                                        <el-upload :limit="1" :action="action" :on-success="beforeLoadF" list-type="picture-card" :on-remove="removeImgF">
                                            <i class="el-icon-plus"></i>
                                        </el-upload>
                                        <div class="uload-name">身份证人像面</div>
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item label="*手机号：">
                                <div class="code">
                                    <input style="width: 563px" v-model="info.accountPhone" readonly type="text" />
                                </div>
                            </el-form-item>
                            <el-form-item label="手机验证码:">
                                <div class="code">
                                    <input style="width: 563px" v-model="form.smsCode" type="text" />
                                    <button @click="getCode" v-show="!countDownShow">发送验证码</button>
                                    <button @click="settime" :disabled="isdisabledFn" v-show="countDownShow">
                                        {{ countDown }}
                                    </button>
                                </div>
                            </el-form-item>
                            <!-- 底部按钮 -->
                            <div class="foot-btn">
                                <el-button class="f-btn" @click="confirmation">确认认证</el-button>
                            </div>
                        </el-form>
                    </div>
                    <!-- 个人认证个人 -->
                    <div v-if="active == 6">
                        <div class="no-win" v-if="lose == 0">
                            <img src="../../assets/images/serve/qr.png" alt="" />
                            入驻开店成功！现在可以：查看我的 <span @click="goMyShop">店铺中心</span>
                        </div>
                        <div class="no-win" v-else>
                            <img src="../../assets/images/serve/qr.png" alt="" />
                            很遗憾，审核失败原因: <span>{{ shopInfo.errorMsg }}</span>
                            <p>
                                请
                                <span @click="goSettled">重新填写</span>
                                缴纳的交易保证金已退还平台钱包。
                            </p>
                        </div>
                    </div>
                    <!-- cz jiange -->
                </div>
            </div>
        </div>
        <!-- 客服 -->
        <div class="chat">
            <img src="../../assets/images/home/chat.png" alt="" />
        </div>
        <Footer />
    </div>
</template>

<script>
import QRCode from "qrcodejs2";
import Footer from "@/components/footer.vue";
import axios from "axios";
import { VueEditor } from "vue2-editor";
export default {
    components: { Footer, VueEditor },
    data() {
        return {
            active: 0, //当前状态
            obtion: 0, //默认0正常流程走  其余为等待操作中
            lose: 0, //0为成功  其余为失败  重新编写
            stepList: [
                //所有进度内容
                { id: 0, name: "店铺信息" },
                { id: 1, name: "宣传图" },
                { id: 2, name: "公司介绍" },
                { id: 3, name: "预览" },
                { id: 4, name: "信息认证" },
                { id: 5, name: "入驻成功" },
            ],
            // 创建店铺  修改店铺使用参数 接口/shop/joinShop创建    /shop/updateShopInfo修改
            form1: {
                name: "",
                province: "",
                city: "",
                headPic: "",
                type: 1,
                images: "",
                msg: "",
                wxCode: "",
                shopClassify: [],
            },
            weCode: [], //饿了么上传回显  二维码
            headPic: [], //饿了么上传回显  头像
            fileList: [], //饿了么上传回显  轮播图
            proList: [], //省
            cityList: [], //市
            contentList: [
                //富文本详情
                { title: "", content: "" },
                { title: "", content: "" },
            ],
            shopInfo: null, //店铺详情
            // 支付详情
            payForm: {
                payType: 0, //0余额  1支付宝 2微信
                payPsd: "",
            },
            form: {
                // 实名验证
                smsCode: "",
                userName: "",
                idNumber: "",
                idCardImgA: "",
                idCardImgB: "",
            },
            qrCodeShow: false, //二维码显示
            radio: 0, //zhifu
            activeInput: 0,
            captchas: [
                // 密码
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },
                { num: "" },
            ],
            info: {}, //个人信息
            action: this.$util.upload, //上传接口地址
            allClass: [], //所有分类
            // 倒计时
            countDownShow: false,
            count: 60,
            countDown: "",
            isdisabledFn: false,
            needMoney: "",
            code: "",
            payId: "",
            codePayState: 0,
        };
    },

    watch: {
        // 监听省的改变进行市级监听获取
        "form1.province"(val) {
            if (val != "") {
                this.getCity();
            }
        },
    },
    mounted() {
        // if (this.$route.query.active) {
        //     switch (this.$route.query.active) {
        //         case '1':
        //             this.active = 2
        //             break;
        //         case '2':
        //             this.active = 4
        //             break;
        //         case '3':
        //             this.active = 4
        //             this.obtion = 1
        //             break;
        //         case '4':
        //             this.active = 5
        //             break;
        //         case '5':
        //             this.active = 6
        //             this.lose = 1
        //             break;

        //         default:
        //             break;
        //     }
        // }
        this.getPro();
        this.getClass();
        this.getInfo();
        this.getMoney();
    },
    methods: {
        getMoney() {
            this.$util
                .post("/sys-param/list", {
                    paramKey: "shop_bond_personal",
                })
                .then((res) => {
                    console.log(res);
                    this.needMoney = res.data[0].paramValue;
                });
        },
        getInfo() {
            this.$util.post("/user/info").then((res) => {
                this.info = res.data;
                this.getShop();
            });
        },
        // 返回首页
        goBack() {
            this.$router.push({ path: "/" });
        },
        //  获取所有分类 并新增status进行多选框进行筛选
        getClass() {
            this.$util.post("/shop/allClassify").then((res) => {
                res.data.forEach((item) => {
                    item.littleList.forEach((el) => {
                        el.status = false;
                    });
                });
                this.allClass = res.data;
            });
        },
        // 获取我的店铺信息
        getShop() {
            this.$util.post("/shop/myShop").then((res) => {
                this.shopInfo = res.data;
                // 如果存在店铺 并且不是从初始页面进来 url的参数不等于空就 根据state值进行页面的进度跳转

                if (this.shopInfo && !this.$route.query.num) {
                    switch (this.shopInfo.state) {
                        case 1:
                            this.active = 2;
                            break;
                        case 2:
                            this.active = 4;
                            break;
                        case 3:
                            this.active = 4;
                            this.obtion = 1;
                            break;
                        case 4:
                            this.active = 5;
                            break;
                        case 5:
                            this.active = 6;
                            this.lose = 1;
                            break;

                        default:
                            break;
                    }
                    /**
                     * @param  state
                     * state = 5等于审核失败
                     * 不等于5得情况下的上一步回显
                     */

                    if (this.shopInfo.state != 5) {
                        let fileList = [],
                            headPic = [],
                            weCode = [];
                        // 轮播图
                        this.shopInfo.images.split(",").forEach((item) => {
                            fileList.push({
                                url: this.$util.host + item,
                                response: { data: { url: item } },
                            });
                        });
                        // 头像
                        headPic.push({ url: this.$util.host + this.shopInfo.headPic });
                        // 二维码
                        weCode.push({ url: this.$util.host + this.shopInfo.wxCode });
                        this.fileList = fileList;
                        this.headPic = headPic;
                        this.weCode = weCode;
                        this.form1.name = this.shopInfo.name;
                        this.form1.province = this.shopInfo.province;
                        this.$nextTick(() => {
                            this.form1.city = this.shopInfo.city;
                        });
                        this.form1.headPic = this.shopInfo.headPic;
                        this.form1.type = 1;
                        this.form1.images = this.shopInfo.images;
                        this.form1.msg = this.shopInfo.msg;
                        this.form1.wxCode = this.shopInfo.wxCode;
                        this.form1.shopClassify = this.shopInfo.shopClassify;
                        // 分类回显 遍历获取数据
                        this.allClass.forEach((item) => {
                            item.littleList.forEach((val) => {
                                this.shopInfo.shopClassify.forEach((el) => {
                                    if (el.littleIds.includes(val.id)) {
                                        val.status = true;
                                    }
                                });
                            });
                        });
                        //  详情
                        // 如果存在详情 便赋值
                        if (this.shopInfo.details.length > 0) {
                            this.contentList = this.shopInfo.details;
                        }
                    }
                }
            });
        },
        // 获取省
        getPro() {
            this.$util.post("/address/provinces").then((res) => {
                this.proList = res.data;
            });
        },
        getCity() {
            this.form1.city = "";
            this.$util
                .post("/address/city", {
                    fid: this.form1.province,
                })
                .then((res) => {
                    this.cityList = res.data;
                });
        },
        // 上传
        beforeLoad(response, file, fileList) {
            // console.log(response);
            // console.log(file);
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form1.headPic = imgs.join(",");
        },
        removeImg(file, fileList) {
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form1.headPic = imgs.join(",");
        },
        // 上传二维码
        beforeLoadW(response, file, fileList) {
            // console.log(response);
            // console.log(file);
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form1.wxCode = imgs.join(",");
        },
        removeImgW(file, fileList) {
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form1.wxCode = imgs.join(",");
        },
        // 轮播上传
        beforeLoadL(response, file, fileList) {
            // console.log(response);
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form1.images = imgs.join(",");
        },
        removeImgL(file, fileList) {
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form1.images = imgs.join(",");
            console.log(this.form1);
        },
        // 重写
        rewrite() {
            this.active = 0;
        },
        // 获取支付状态
        payGetState() {
            this.$util
                .post("/center/myOrder/getRecordState", {
                    id: this.payId,
                })
                .then((res) => {
                    console.log(res);
                    this.codePayState = res.data.state;
                });
        },
        creatQrCode() {
            this.qrCodeShow = true;
            var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: this.createdCode,
                width: 257,
                height: 257,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
            });
        },
        next(num) {
            console.log(this.form1);
            if (num == 0) {
                let arr = [];
                this.allClass.forEach((item) => {
                    let some = item.littleList.filter((el) => {
                        return el.status;
                    });

                    if (some.length == 0) {
                        return;
                    } else {
                        let list = some.map((val) => {
                            return val.id;
                        });
                        let obj = {
                            baseId: item.id,
                            littleIds: list,
                        };
                        arr.push(obj);
                    }
                });
                this.form1.shopClassify = arr;

                if (this.form1.headPic == "") {
                    return this.$message.error("请上传头像");
                }

                if (this.form1.name == "" || this.form1.msg == "") {
                    return this.$message.error("请填写店铺信息");
                }

                if (this.form1.province == "") {
                    return this.$message.error("请选择地区");
                }

                if (
                    this.form1.province == "710000" ||
                    this.form1.province == "810000" ||
                    this.form1.province == "820000"
                ) {
                    return this.$message.error("当前地区不可用");
                }

                if (this.form1.city == "") {
                    return this.$message.error("请选择地区");
                }

                if (this.form1.shopClassify.length == 0) {
                    return this.$message.error("请选择分类");
                }
                this.active = ++num;
            }
            // 创建店铺
            else if (num == 1) {
                if (this.shopInfo) {
                    // 修改店铺详情信息
                    if (this.shopInfo.state != 5) {
                        this.$util
                            .postJ("/shop/updateShopInfo", this.form1, { type: true })
                            .then((res) => {
                                if (res.code == 200) {
                                    this.active = ++num;
                                    this.shopInfo = res.data;
                                    // this.getShop()
                                } else {
                                    this.$message.error(res.message);
                                }
                            });
                    } else {
                        // 创建店铺
                        this.$util.postJ("/shop/joinShop", this.form1, { type: true }).then((res) => {
                            if (res.code == 200) {
                                this.active = ++num;
                                this.getShop();
                            } else {
                                this.$message.error(res.message);
                            }
                        });
                    }
                } else {
                    // 创建店铺
                    this.$util.postJ("/shop/joinShop", this.form1, { type: true }).then((res) => {
                        if (res.code == 200) {
                            this.active = ++num;
                            this.getShop();
                        } else {
                            this.$message.error(res.message);
                        }
                    });
                }
            } else if (num == 2) {
                var content = [];
                // 添加详情内容  富文本
                if (this.contentList[0].content == "" || this.contentList[0].title == "") {
                    this.$message.error("请上传店铺介绍");
                } else {
                    this.$util
                        .postJ("/shop/setDet", this.contentList, { type: true })
                        .then((res) => {
                            if (res.code == 200) {
                                this.active = ++num;
                                this.getShop();
                                // console.log(this.shopInfo.shopClassify,999999)
                            } else {
                                this.$message.error(res.message);
                            }
                        });
                }
            } else if (num == 4) {
                if (
                    (this.payForm.payType == 0 && this.payForm.payPsd == "") ||
                    (this.payForm.payType == 0 && this.payForm.payPsd.length < 6)
                ) {
                    return this.$message.error("请输入支付密码");
                }
                // 支付
                this.$util.post("/shop/joinPay", this.payForm).then((res) => {
                    if (res.code == 200) {
                        if (this.payForm.payType == 1 || this.payForm.payType == 2) {
                            this.createdCode = res.data.code;
                            this.creatQrCode();
                            this.payId = res.data.id;
                            let timmer = setInterval(() => {
                                if (this.codePayState == "0") {
                                    this.payGetState();
                                } else {
                                    clearInterval(timmer);
                                    if (this.codePayState == 1) {
                                        this.goSettled()
                                        return this.$message.error("支付超时");
                                    } else if (this.codePayState == 2) {
                                        this.obtion = 1;
                                        return this.$message.success("支付成功");
                                    }
                                }
                            }, 5000);
                        } else {
                            this.obtion = 1;
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                });
            } else {
                this.active++;
            }
        },
        // 信息详情
        // 富文本上传图片
        handleImageAdded(file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();

            formData.append("file", file);
            console.log(new FormData());
            axios({
                url: this.$util.host + "/file/upload",
                method: "POST",
                data: formData,
            })
                .then((result) => {
                    let url = this.$util.host + result.data.data.url; // Get url from response
                    console.log(url);
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // 富文本上传图片
        handleImageAdded2(file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();

            formData.append("file", file);
            console.log(new FormData());
            axios({
                url: this.$util.host + "/file/upload",
                method: "POST",
                data: formData,
            })
                .then((result) => {
                    let url = this.$util.host + result.data.data.url; // Get url from response
                    console.log(url);

                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // 正面身份证
        beforeLoadZ(response, file, fileList) {
            // console.log(response);
            // console.log(file);
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form.idCardImgA = imgs.join(",");
        },
        removeImgZ(file, fileList) {
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form.idCardImgA = imgs.join(",");
        },
        // 反面身份证
        beforeLoadF(response, file, fileList) {
            // console.log(response);
            // console.log(file);
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form.idCardImgB = imgs.join(",");
        },
        removeImgF(file, fileList) {
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form.idCardImgB = imgs.join(",");
        },
        pre() {
            this.active--;
        },
        // 验证码
        getCode() {
            this.$util
                .post("sys-sms/sms-send", {
                    phone: this.info.accountPhone,
                    action: "Other",
                })
                .then((res) => {
                    console.log(res);
                    if (res.code == 200) {
                        this.countDownShow = true;
                        this.settime();
                    }
                });
        },

        settime() {
            if (this.count == 0) {
                this.count = 60;
                this.countDownShow = false;
                return false;
            } else {
                this.countDownShow = true;
                this.isdisabledFn = true;
                this.countDown = "重新发送" + "(" + this.count + ")";
                this.count--;
            }
            setTimeout(() => {
                this.settime();
            }, 1000);
        },
        // 输入密码
        adjust(index) {
            let dom = document.getElementById("captcha" + this.activeInput);
            if (index !== this.activeInput && dom) {
                dom.focus();
            }
        },
        // 控制前后方向
        inputDirection(index) {
            let val = this.captchas[index].num;
            // 回退键处理
            if (event.keyCode == 8 && val == "") {
                // 重新校准
                let dom = document.getElementById("captcha" + (index - 1));
                this.activeInput = index - 1;
                if (dom) dom.focus();
            }
            if (event.keyCode != 8 && val != "") {
                let dom = document.getElementById("captcha" + (index + 1));
                this.activeInput = index + 1;
                if (dom) dom.focus();
            }
        },
        // 输入框相互联动
        inputFinash(index) {
            let val = this.captchas[index].num;
            this.activeInput = val ? index + 1 : index - 1;
            let dom = document.getElementById("captcha" + this.activeInput);
            if (dom) dom.focus();
            if (index == this.captchas.length - 1) {
                let code = this.captchas.map((x) => x.num).join("");
                if (code.length == 6) {
                    this.payForm.payPsd = code;
                }
            }
        },
        // 确认认证
        confirmation() {
            this.$util.post("shop/examine/personal", this.form).then((res) => {
                if (res.code == 200) {
                    this.active++;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        goMyShop() {
            this.$router.push({ path: "/myShop" });
        },
        goSettled() {
            console.log(1231);

            this.$router.push({ path: "/settled" });
        },
    },
};
</script>
<style>
.shopDetail {
    padding: 0 20px;
}
.shopDetail img {
    width: 100%;
}
</style>
<style lang="scss" scoped>
.head {
    padding: 12px 0;
    background: #515151;
    .nav {
        width: 1200px;
        display: flex;
        margin: 0 auto;
        color: #fff;
        div {
            display: flex;
            cursor: pointer;
            align-items: center;
            padding-right: 53px;
            font-size: 14px;
        }
        .bor {
            border-right: 1px solid #707070;
            margin-right: 50px;
        }
    }
}
.main-box {
    background: #f6f6f6;
}
.main {
    width: 1200px;
    background: #fff;
    padding: 30px 0 100px;
    margin: 0 auto;
}
.step {
    display: flex;
    justify-content: space-between;
    padding: 17px 30px;
    background: #fce3e5;
    .step-item {
        display: flex;
        align-items: center;
        div {
            width: 22px;
            height: 22px;
            border: 1px solid #ccc;
            border-radius: 100%;
            margin-right: 10px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.state {
    padding: 20px 0 10px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #333333;
    span {
        color: #e40012;
    }
    img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
}
.avatar {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 40px;
    color: #999;
    .avatar-item {
        margin-left: 25px;

        /deep/.el-upload--picture-card {
            width: 100px;
            height: 100px;
            border-radius: 100%;
            background: #fff;
            line-height: 110px;
        }
        /deep/.el-upload-list--picture-card .el-upload-list__item {
            width: 100px;
            height: 100px;
            border-radius: 100%;
        }
    }
}
/deep/.el-form-item__label {
    color: #999;
    font-size: 16px;
}
.codes {
    display: flex;
    align-items: center;
    span {
        margin: 0 10px;
    }
}
.title-t {
    font-size: 16px;
    color: #999999;
}
.check {
    margin-top: 25px;
    .check-head {
        padding: 13px 40px;
        background: #f6f6f6;
        color: #222;
        font-size: 18px;
    }
    .check-box {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        flex-wrap: wrap;
        font-size: 16px;
        color: #222;
        .check-item {
            display: flex;
            align-items: center;
            margin-top: 30px;
            min-width: 16%;
        }
    }
}
.upload {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 30px 0;
    color: #999999;
    .lable {
        margin-right: 20px;
    }
}
/deep/.el-upload--picture-card {
    width: 198px;
    height: 124px;
    line-height: 130px;
    // margin-right: 90px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 198px;
    height: 124px;
    margin-right: 90px;
    // margin-bottom: 50px;
}
.foot-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    font-size: 16px;
    cursor: pointer;
    color: #e40012;
    .f-btn {
        padding: 15px 180px;
        background: #e40012;
        color: #fff;
        font-size: 18px;
        margin-left: 40px;
    }
}
.publicity {
    padding: 57px 0 0;
    display: flex;
    align-items: center;
}
.chat {
    width: 97px;
    height: 103px;
    position: fixed;
    right: 0;
    z-index: 10000;
    top: 40%;
    img {
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}
.content-info {
    padding: 30px;
    background: #f6f6f6;
    color: #222;
    font-size: 14px;
    max-height: 1000px;
    overflow-y: visible;
    line-height: normal;
}
.shop-box {
    position: relative;
    margin: 26px 0 40px;
}
.chat-img {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #707070;
    padding: 15px 0 0;
    font-size: 16px;
    img {
        width: 120px;
        height: 40px;
    }
}
.shop-info {
    // width: 1200px;
    // margin: 0 auto;
    display: flex;
    .shop-img {
        width: 198px;
        height: 248px;
        margin-right: 20px;
        // background: #515151;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .shop-title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 14px;
        color: #666;
        margin-bottom: 20px;
        .shop-name {
            font-size: 20px;
            color: #222222;
            margin-right: 10px;
        }
        .shop-tag {
            display: flex;
            margin: 0 80px 0 60px;
            div {
                font-size: 14px;
                color: #666;
                padding-right: 20px;
                span {
                    color: #e40012;
                }
            }
            .bor {
                border-right: 1px solid #707070;
                margin-right: 20px;
            }
        }
        .dw {
            width: 19px;
            height: 24px;
            display: block;
            margin: 0 9px 0 32px;
        }
    }
    .shop-business {
        padding: 20px 30px 20px 20px;
        background: #f6f6f6;
        color: #666666;
        margin: 0 0 10px;
    }
}
.swiper {
    // background: lime;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
    }
}
/deep/.el-carousel__button {
    width: 22px;
    height: 22px;
    background: #fff;
    border-radius: 100%;
    opacity: 1;
}
/deep/.el-carousel__indicator.is-active button {
    background: #e40012;
}
.mian {
    // width: 1200px;
    margin: 80px auto 30px;
    border: 1px solid #e3e3e3;
}
.main-title {
    padding: 17px 20px;
    font-size: 20px;
    color: #222;
    background: #f2f2f2;
}
// 分类
.classification {
    background: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e3e3e3;
    border-top: 1px solid #e3e3e3;
}
.slece {
    cursor: pointer;
    margin-right: 80px;
    font-size: 16px;
    font-weight: bold;
}
.classification-content {
    // width: 1200px;
    // margin: 0 auto;
    height: 400px;
    line-height: 400px;
    font-size: 22px;
    text-align: center;
    background: #d1d1d1;
}
.pagination {
    // width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
    border: 1px solid #e3e3e3;
}
/deep/.el-pager li {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px !important;
    border: 1px solid #2ea7e0;
    margin: 0 3px;
    color: #2ea7e0 !important;
}
/deep/.el-pagination.is-background .el-pager li {
    background: transparent;
}
/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px !important;
    // border: 1px solid #2ea7e0;
    background: transparent;
    margin: 0 3px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    color: #fff !important;
}
/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
    font-size: 24px;
    color: #2ea7e0;
}
/deep/.el-pager li.active + li {
    border: 1px solid #2ea7e0;
}
.total {
    color: #2ea7e0;
    font-weight: bold;
    font-size: 16px;
}
.jump {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 0 20px 0 40px;
    color: #2ea7e0;
    input {
        width: 40px;
        height: 40px;
        border: 1px solid #0f6c97;
        font-size: 14px;
        color: #2ea7e0;
        outline: none;
        margin: 0 10px;
        border-radius: 4px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
.nice-shop {
    display: flex;
    flex-wrap: wrap;
}
.good {
    width: 185px;
    padding: 20px;
    border: 1px solid #e3e3e3;
    img {
        width: 100%;
        height: 178px;
    }
    div {
        font-size: 14px;
        color: #222222;
        margin: 20px 0 10px;
    }
    span {
        color: #e40012;
        font-size: 14px;
    }
}
.introduce {
    width: 1200px;
    margin: 0 auto;
    font-size: 24px;
    color: #222222;
    font-weight: bold;
}
.introduce-title {
    padding: 30px 0 60px;
    background: #f8f8f8;
    font-size: 18px;
    font-weight: bold;
    color: #222222;
    text-align: center;
    margin: 30px 0 20px;
    div {
        width: 1200px;
        margin: 30px auto 20px;
        text-align: left;
        font-size: 14px;
        font-weight: normal;
    }
}
.introduce-title-t {
    padding: 30px 0 60px;
    background: #f8f8f8;
    font-size: 18px;
    font-weight: bold;
    color: #222222;
    text-align: center;
    margin-bottom: 20px;
    .sange {
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: 50px auto 0;
        div {
            width: 277px;
            height: 370px;
            border: 1px solid #707070;
            background: #fff;
        }
    }
    .title-f {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 800px;
        margin: 50px auto 0;
        div {
            width: 183px;
            height: 183px;
            border: 1px solid #707070;
            background: #fff;
            border-radius: 100%;
            margin-right: 50px;
            margin-bottom: 50px;
        }
    }
}
.introduce-title-f {
    padding: 30px 0 34px;
    background: #f8f8f8;
    margin-bottom: 30px;
    .f-title {
        color: #222222;
        font-size: 18px;
        text-align: center;
        display: block;
        margin-bottom: 80px;
        font-weight: bold;
    }
    div {
        width: 1200px;
        margin: 0 auto 20px;
        height: 400px;
        border: 1px solid #707070;
        background: #fff;
    }
}
.comment-box {
    width: 1200px;
    margin: 0 auto 20px;
}
.comment {
    display: flex;
    padding: 30px 20px;
    border-bottom: 1px solid #f1f1f1;
    img {
        width: 82px;
        height: 82px;
    }
    .comment-info {
        flex: 1;
        margin-left: 20px;
        font-size: 14px;
        color: #999999;
        .user-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #222;
            .name {
                display: flex;
                align-items: center;
                div {
                    margin-right: 20px;
                }
            }
        }
    }
}
.comment-content {
    margin: 13px 0 20px;
    font-size: 14px;
    color: #666;
}
.comment-serve {
    margin-bottom: 10px;
    span {
        color: #e40012;
    }
}
.hint {
    font-size: 16px;
    color: #999999;
    margin: 30px 0;
}
.payment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    padding: 15px 30px;
    border: 1px solid #eeeeee;
    margin-bottom: 20px;
    color: #999;
}
.raido {
    display: flex;
    align-items: center;
}
.raido-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-left: 10px;
    align-items: center;
    color: #222;
    div {
        display: flex;
        align-items: center;
    }
    img {
        margin-right: 10px;
        // margin-top: ;
    }
    span {
        color: #999999;
        font-size: 14px;
        margin-left: 10px;
    }
}
.deposit {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #222;
    span {
        color: #e40012;
    }
    .price {
        padding: 15px 48px;
        border: 1px solid #eeeeee;
        margin-right: 20px;
        text-align: center;
        div {
            color: #999999;
            font-size: 14px;
            margin-top: 10px;
        }
    }
}
.paypass {
    display: flex;
    align-items: center;
    input {
        width: 64px;
        height: 64px;
        border: 1px solid #d1d1d1;
        outline: none;
        text-align: center;
        line-height: 64px;
        font-size: 60px;
        margin-right: 20px;
    }
}
::-ms-clear {
    display: none;
}

::-ms-reveal {
    display: none;
}
.backJd {
    margin: 20px 0;
    color: #222;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.ysmoney {
    padding: 15px 60px;
    border: 1px solid #eeeeee;
    color: #e40012;
    font-size: 16px;
    // text-align: center;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
}
.privacy {
    font-size: 16px;
    color: #00a71c;
    margin: 30px 0 40px;
}
.qr-code {
    width: 257px;
    height: 257px;
    margin-left: 20px;
    background: red;
    img {
        width: 100%;
        height: 100%;
    }
}
.s-title {
    margin-top: 40px;
    font-size: 16px;
    color: #222;
    margin-bottom: 20px;
}
.blue {
    width: auto !important;
    height: auto !important;
    text-align: center;
    color: #2ea7e0;
    line-height: normal;
}
.upload-item {
    display: flex;
    align-items: center;
    .upload-son {
    }
}
.code {
    height: 48px;
    // border: 1px solid #dcdfe6;
    display: flex;
    align-items: center;
    // width: 500px;
    input {
        height: 100%;
        // border: none;
        outline: none;
        padding: 0 15px;
        border-radius: 4px;
        font-size: 16px;
        border: 1px solid #eeeeee;
        margin-right: 10px;
    }
    button {
        background-color: transparent;
        border: none;
        color: #e40012;
        font-weight: bold;
        font-size: 14px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
input::-webkit-input-placeholder {
    color: #c0c4cc;
}
input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c0c4cc;
}
input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c0c4cc;
}
input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c0c4cc;
}
.verification {
    display: flex;
    margin-top: 10px;
    align-items: center;
    .text {
        font-size: 14px;
        color: #666666;
        cursor: pointer;
        margin-left: 10px;
        span {
            color: #e40012;
        }
    }
}
.upload {
    display: flex;
    align-items: center;
    .uload-name {
        font-size: 14px;
        color: #2ea7e0;
        margin-left: 57px;
    }
    /deep/.el-upload--picture-card {
        width: 198px;
        height: 124px;
        background: #fff;
        line-height: 134px;
        margin-right: 20px;
    }
    /deep/.el-upload-list--picture-card .el-upload-list__item {
        width: 198px;
        height: 124px;
    }
}

.no-win {
    text-align: center;
    font-size: 18px;
    color: #999999;
    margin-top: 150px;
    margin-bottom: 70px;
    span {
        cursor: pointer;
        color: #e40012;
    }
    img {
        width: 56px;
        height: 56px;
        display: block;
        margin: 0 auto 20px;
    }
}
</style>
